<template lang="pug">
	div(class="UploadPic" :class="{'UploadPic--loading': loading, 'UploadPic--error': isError}")
		div(class="full-size img-container" v-if="isImgAvailable")
			img(
				class="full-size img"
				:src="imgUrl"
			)

		v-layout(
			v-if="loading"
			align-center
			justify-center
			class="full-size progress"
		)
			v-progress-circular(
				:rotate="360"
				:size="100"
				:width="15"
				:value="progress"
				color="teal"
			)

		v-layout(
			class="full-size message ma-0"
			:class="{'message--hover': isImgAvailable && !loading}"
			justify-center
			align-center
			fill-height
			wrap
			row
		)
			div(class="text-xs-center message__text" :class="{'white--text': isImgAvailable}")
				div: v-icon(x-large :class="{'white--text': isImgAvailable, 'red--text': isError}") mdi-image-outline
				div Arrastra una imagen
				div o
				v-btn(@click="input.click()" :color="isError ? 'error':''") Click aquí

			div(class="message__backdrop full-size" v-if="isImgAvailable")


		input(
			class="d-none"
			type="file"
			accept="image/*"
			@change="onFileSelected()"
			ref="input"
		)
</template>

<script>
import {buckets} from '@/config';
import {buildFileUrl} from '@/modules/common/helpers';
import firebase from '@/modules/common/firebase';


import {v1 as uuidv1} from 'uuid';
export default {
	name: 'UploadPic',
	data() {
		return {
			loading: false,
			progress: 0,
			isImgLoaded: false,
			imgUrl: '',
			isTouched: false
		}
	},

	props: {
		value: {
			type: String,
			default: ''
		},
		errorMessage: {
			type: String,
			default: ''
		}
	},

	watch: {
		value(newVal) {
			this.imgUrl = newVal;
		}
	},

	methods: {
		onFileSelected() {
			this.isTouched = true;
			const file = this.input.files[0];
			this.imgUrl = URL.createObjectURL(file);
			this.isImgLoaded = false;
			this.uploadFile(file).then(
				this.handleFileUploadSuccess.bind(this),
				this.handleFileUploadError.bind(this)
			);
		},

		uploadFile(file) {
			const user = firebase.auth().currentUser;
			const storage = firebase.storage();
			const uuid = uuidv1();
			const fileRef = storage.refFromURL(`gs://${buckets.TEMP}/users/${user.uid}/portrait/${uuid}`);

			const uploadTask = fileRef.put(file);

			this.loading = true;
			const setLoadingFalse = () => (this.loading = false);
			uploadTask.then(setLoadingFalse.bind(this), setLoadingFalse.bind(this));

			uploadTask.on('state_changed', this.onProgressChange.bind(this));

			return uploadTask;
		},

		onProgressChange(data) {
			this.progress = (data.bytesTransferred / data.totalBytes) * 100;
		},

		handleFileUploadSuccess(response) {
			URL.revokeObjectURL(this.imgUrl);
			const {bucket, fullPath} = response.metadata;
			this.imgUrl = buildFileUrl({bucket, id: fullPath});
			this.isImgLoaded = true;
			this.$emit('input', this.imgUrl);
		},

		handleFileUploadError(error) {
			this.$notify({
				title: 'Firebase',
				text: error.message,
				type: 'error'
			})
		},
	},

	computed: {
		input() {
			return this.$refs.input;
		},

		isImgAvailable() {
			return !!this.imgUrl;
		},

		isError() {
			return !!this.errorMessage;
		}
	}
}
</script>

<style lang="less" scoped>
	.UploadPic {
		z-index: 1;
		position: relative;
		overflow: hidden;
		height: 200px;

		&:hover .message--hover {
			position: absolute;
			top: 0;
		}

		.layout.progress {
			position: absolute;
			top: 0;
			margin: 0;
		}
	}

	.UploadPic--loading .img{
		filter: blur(10px);
	}

	.UploadPic--error .message {
		border-color: red;
		color: red;
	}

	.message {
		border: dashed 1px;
		position: relative;

		.message__text {
			z-index: 2;
		}

		.message__backdrop {
			top: 0;
			background-color: black;
			opacity: 0.8;
			position: absolute;
			z-index: 1;
		}
	}

	.full-size {
		height: 100%;
		width: 100%;
	}
</style>
