<template lang="pug">
v-content.CreateTour
  v-container
    h2(class) {{ isNewTour ? 'Nuevo Tour' : 'Editar Tour' }}
    v-form
      v-layout(row, wrap)
        v-flex(xs12, md5)
          v-text-field(
            label="Nombre",
            hint="Nombre del tour que aparecerá en la presentación",
            v-model="$v.form.name.$model",
            :error-messages="nameErrors",
            @blur="$v.form.name.$touch()"
          )
          br
          v-textarea(
            outline,
            label="Descripción",
            v-model="$v.form.description.$model",
            hint="Da una breve descripción del tour que quieres crear",
            :error-messages="descriptionErrors",
            @blur="$v.form.description.$touch()"
          )

        v-flex.pt-3.first-section(
          xs12,
          sm6,
          offset-sm3,
          md4,
          offset-md2,
          lg3,
          offset-md1
        )
          h3.mb-2.text-xs-center Portada
          upload-pic.full-height(
            v-model="$v.form.coverPicUrl.$model",
            :error-message="coverPicUrlError"
          )

      .mt-5
        h2.text-xs-center.text-md-left.mb-1 Panoramas
        pano-upload-manager(v-model="$v.form.panoramas.$model")

      v-btn.submit-button.mt-4(
        @click="submit",
        color="primary",
        :loading="loading"
      ) Guardar
      v-btn.submit-button.mt-4(@click="goBack") Cancelar
</template>

<script>
import PanoUploadManager from "@/modules/common/components/PanoUploadManager.component";
import UploadPic from "@/modules/common/components/UploadPic.component";
import { required, url, minLength, maxLength } from "vuelidate/lib/validators";
import { firestore, analytics, auth } from "@/modules/common/firebase";
import { getUser, notify } from "@/modules/common/services";

export default {
  name: "CreateTour",
  components: {
    PanoUploadManager,
    UploadPic,
  },
  props: {
    source: String,
  },
  data: () => ({
    form: {
      name: "",
      description: "",
      coverPicUrl: "",
      panoramas: [],
    },
    plan: null,
    user: null,

    tourId: "",

    loading: false,
  }),

  async mounted() {
    this.tourId = this.$route.params.id;


    if (!this.isNewTour) {
      const tourRef = firestore.collection("tours").doc(this.tourId);

      tourRef.get().then((tour) => {
        const { name, description, coverPicUrl, panoramas } = tour.data();
        this.form.name = name;
        this.form.description = description;
        this.form.coverPicUrl = coverPicUrl;
        this.form.panoramas = panoramas;
      });
    }

    await this.getUser();
    this.getPlan();
  },

  methods: {
    submit() {
      const form = this.$v.form;
      if (form.$invalid) {
        this.$v.$touch();

        let msg = "Hay campos no válidos. Verifica los datos introducidos";

        notify({
          type: "error",
          text: msg,
        });
        return;
      }

      if(this.form.panoramas.length > this.plan.maxPanoramas) {
        return notify({
          type: "error",
          text: `Has excedido el número de panoramas permitido de ${this.plan.maxPanoramas}. Subscribete a otro plan`,
        });
      }

      const data = this.transformData();

      let request;
      if (this.isNewTour) {
        request = this.createTour(data);
      } else {
        request = this.editTour(data);
      }

      const setLoadingToFalse = () => (this.loading = false);
      this.loading = true;
      request.then(setLoadingToFalse, setLoadingToFalse);
    },

    goBack() {
      this.$router.go(-1);
    },

    async getUser() {
      this.user = await getUser();
    },

    async canUserCreateTour() {
      return this.plan.maxTourCount > this.user.tourCount;
    },

    async getPlan() {
      const user = this.user;

      const planRef = await firestore
        .collection("plans")
        .doc(user.planId)
        .get();

      this.plan = planRef.data();
    },

    async createTour(data) {
      const canUserCreateTour = await this.canUserCreateTour();

      if (!canUserCreateTour) {
        return notify({
          text: "Has excedido el número de tours que puedes crear. Subscribete a otro plan",
          type: "error",
        });
      }

      const request = firestore.collection("tours").add(data);

      request.then(
        this.handleSuccessResponse.bind(this, data),
        this.handleErrorResponse.bind(this)
      );

      request.then(() =>
        notify({
          text: "Tour ha sido creado",
          type: "success",
        })
      );

      return request;
    },

    editTour(data) {
      const newTourDb = firestore.collection("tours").doc(this.tourId);
      const request = newTourDb.update(data);

      request.then(
        this.handleSuccessResponse.bind(this, data),
        this.handleErrorResponse.bind(this)
      );

      request.then(() =>
        notify({
          text: "El tour ha sido guardado",
          type: "success",
        })
      );

      return request;
    },

    handleErrorResponse(error) {
      notify({
        title: "firebase:",
        text: error.message,
        type: "error",
      });
    },

    async handleSuccessResponse(tour) {
      const user = await this.$getUser();
      analytics.logEvent("create_tour", {
        tour_count: user.tourCount,
        tour_panorama_count: tour.panoramas.length,
      });

      this.$router.push({ name: "myTours" });
    },

    transformData() {
      const { name, description, coverPicUrl } = this.form;
      const panoramas = this.form.panoramas.map((pano) => {
        return { ...pano, links: pano.links || [] };
      });
      const ownerId = auth.currentUser.uid;

      return {
        name,
        description,
        panoramas,
        coverPicUrl,
        ownerId,
      };
    },
  },

  computed: {
    isSubmitDisabled() {
      return this.$v.$error;
    },
    nameErrors() {
      const errors = [];
      if (this.$v.form.name.$error) {
        errors.push("Este campo es requerido");
      }

      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;

      this.$v.form.description.$invalid &&
        errors.push("Este campo es requerido");
      return errors;
    },

    coverPicUrlError() {
      let error;

      if (this.$v.form.coverPicUrl.$error) {
        error = "Este campo es requerido";
      }

      return error;
    },

    isNewTour() {
      return !this.tourId;
    },
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      description: {
        required,
        maxLength: maxLength(1000),
      },
      coverPicUrl: {
        url,
        required,
      },
      panoramas: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(1),
      },
    },
  },
};
</script>

<style lang="less" scoped>
.full-height {
  height: 100%;
}

.first-section {
  height: 300px;
}

.submit-button {
  float: right;
}
</style>


