<template lang="pug">
	div(class="UploadManager")
		v-layout(row wrap align-center fill-height class="UploadManager__container")
			v-flex(
				v-for="pano in panoramas" :key="pano.id"
				xs6 offset-xs3 sm3 offset-sm0 md3 lg2 
			)
				pano(
					v-model="pano.name"
					:loading="pano.loading"
					:progress="pano.progress"
					:img-url="pano.fileUrl"
					@close="remove(pano.id)"
				)

			v-flex(
				v-if="!panoramas.length" 
				class="text-xs-center UploadManager__default-message"
			)
				div: v-icon(x-large class="UploadPic__icon") mdi-panorama
				div
					| No has agregado ningún panorama. Utiliza el&nbsp;
					v-icon mdi-plus-circle
					| &nbsp;para añadir uno.

		v-btn(
			absolute
			dark
			fab
			top
			right
			color="pink"
			class="UploadManager__add-button"
			@click="input.click()"
		)
			v-icon mdi-plus

		input(
			class="d-none"
			type="file"
			multiple
			accept="image/*"
			ref="input"
			@change="onFilesSelected"
		)
</template>

<script>

import Pano from '@/modules/common/components/Pano.component';
import firebase from '@/modules/common/firebase';
import {buildFileUrl, downScaleImg} from '@/modules/common/helpers';
import uniqBy from 'lodash.uniqby';
import {buckets} from '@/config';

import {v1 as uuidv1} from 'uuid';

const user = firebase.auth().currentUser;
const storage = firebase.storage();

const createPano = async (file) => {
	let blob = URL.createObjectURL(file);
	const name = file.name;
	
	file = await downScaleImg(file);

	return {
		id: uuidv1(),
		file,
		progress: 0,
		loading: false,
		fileUrl: blob,
		name: removeFileExtension(name)
	};
};

const removeFileExtension = (str) => {
	return str.replace(/\.[^/.]+$/, "");
}

const isValidPano = (pano) => {
	return (
		pano.fileUrl.startsWith('http') &&
		typeof pano.name === 'string' &&
		typeof pano.id === 'string' &&
		!!pano.name &&
		!!pano.id
	);
};

const transformPano = (pano) => {
	const {name, id, fileUrl, links} = pano;
	return {name, id, fileUrl, links};
}

const calculatePanoProgress = (pano) => {
	return (data) => {
		pano.progress = (data.bytesTransferred / data.totalBytes) * 100;
	};
};

export default {
	name: 'UploadManager',

	components: {
		Pano
	},
	data() {
		return {
			panoramas: []
		}
	},
	props: {
		value: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		input() {
			return this.$refs.input;
		}
	},

	watch: {
		value(newVal=[]) {
			this.panoramas = uniqBy([...newVal, ...this.panoramas], 'id');
		}
	},

	methods: {
		emitVal() {
			const panostoEmit = this.panoramas.filter(isValidPano).map(transformPano);
			this.$emit('input', panostoEmit);
		},

		async onFilesSelected() {
			const files = (Array.from(this.input.files));
			const that = this;
			files.forEach(async (file) => {
				const pano = await createPano(file);
				that.uploadPano(pano);
				that.panoramas.push(pano);
			});

			// reset input to be able to select same image
			this.input.value = '';
		},

		uploadPano(pano) {
			const fileRef = storage.refFromURL(`gs://${buckets.TEMP}/users/${user.uid}/panoramas/${pano.id}`);
			const fileRequestUpload = fileRef.put(pano.file);
			
			pano.loading = true;
			
			fileRequestUpload.on('state_changed', calculatePanoProgress(pano));
			fileRequestUpload.then(this.fileUploadSuccess.bind(this, pano));
			fileRequestUpload.catch(this.fileUploadError.bind(this, pano));
		},

		fileUploadSuccess(pano, response) {
			pano.loading = false;
			const id = response.metadata.fullPath;
			const bucket = response.metadata.bucket;
			const url = buildFileUrl({id, bucket});
			
			URL.revokeObjectURL(pano.fileUrl);
			pano.fileUrl = url;
			this.emitVal();
		},

		fileUploadError(pano, error) {
			this.remove(pano.id);
			this.$emit('error', error);
		},

		remove(id) {
			this.panoramas = this.panoramas.filter(pano => pano.id !== id);
			this.emitVal();
		}
	}
}
</script>

<style scoped>
	.UploadManager {
		border: solid 1px;
		position: relative;
	}

	.UploadManager__container {
		min-height: 300px;
	}
</style>

