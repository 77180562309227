<template lang="pug">
	v-container(class="Pano")
		div
			v-img(
				aspect-ratio="1"
				class="Pano__img"
				:src="imgUrl"
				:class="{'Pano__img--blur': loading}"
			)
			v-progress-circular(
				rotate="-90"
				size="35"
				width="8"
				color="primary"
				class="Pano__progress"
				:value="progress"
				v-if="loading"
			)
			v-btn(
				absolute
				dark
				fab
				small
				color="black"
				class="Pano__close"
				@click="closeEvent"
			)
				v-icon mdi-close

		v-text-field(
			class="Pano__text-input"
			v-model="value"
		)

</template>

<script>

export default {
	name: 'PanoUpload',
	props: {
		loading: {
			type: Boolean,
			value: false
		},
		progress: {
			type: Number,
			value: 0
		},
		imgUrl: {
			type: String,
			required: true,
		},
		value: {
			type: String
		}
	},

	methods: {
		closeEvent() {
			this.$emit('close');
		}
	}
}
</script>

<style>
	.Pano {
		position: relative;
	}

	.Pano__img {
		border-radius: 50%;
	}

	.Pano__img--blur {
		-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
		filter: blur(5px);
	}

	.Pano__close {
		top: 20px;
		right: 30px;
	}


</style>

<style lang="less">
	.Pano__progress.v-progress-circular {
		position: absolute;
		top: 30%;
		left: 40%;
		margin: 0 auto;
	}
</style>



